import * as React from "react";
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Button, Radio, Typography, withStyles  } from "@material-ui/core";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel'
import { API } from "../../../network/API";
import styled from "styled-components";
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import { Question } from "../../../types";
import { generalStore } from "../../../stores/GeneralStore";
import { authStore } from "../../../stores/AuthStore";
import { t } from "../../../i18n/util";
import { Routes } from "../../routers/Routes";
import { history } from "../../routers/history";
import { UserNavigation } from "../../ui/UserNavigation";

const Container = styled.div`
    display: flex;
    justify-content: center;
    padding: 40px;
    padding-bottom: 64px;
`;

const IntroContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 40px;
    padding-bottom: 64px;
    white-space: pre-line;
    & iframe {
        align-self: center;
        margin-top: 50px;
    }

    & ol {
        margin-left: 30px;
    }

    & p {
        margin-bottom: 20px;
    }

    & u {
        display: block;
        margin-bottom: 20px;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: right;
    padding: 40px;
    padding-bottom: 64px;
    & button {
        line-height: normal;
    }
    & p {
        margin-right: 20px;
    }
`;

const TryAgainContainer = styled.div`
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
`;

const TryAgainButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 20px;
`;

const CustomFormLabel = withStyles({
    root: {
        fontSize: "1.5rem",
        lineHeight: 2,
    },
})(FormLabel as any)

function Questions(props: { questions: Question[]; submitFn: (answeredQuestions: Question[]) => void }) {
    const [
        answeredQuestions,
        setAnsweredQuestions
    ] = React.useState<Question[]>(props.questions.map(q => ({...q})))

    const [activeQuestionIndex, setActiveQuestionIndex] = React.useState(0)
    const lastQuestion = activeQuestionIndex === props.questions.length - 1
    const activeQuestion = answeredQuestions[activeQuestionIndex]

    const currAnswerId = activeQuestion.selectedAnswerId || null

    function handleClick() {
        if (!lastQuestion) {
            setActiveQuestionIndex(activeQuestionIndex + 1)
            return
        }

        props.submitFn(answeredQuestions)
    }

    return (
        <>
            <Stepper activeStep={activeQuestionIndex}>
                { props.questions.map((_, i) => (
                    <Step key={i} completed={i < activeQuestionIndex}>
                        <StepLabel>{ }</StepLabel>
                    </Step>
                )) }
            </Stepper>
            <Container>
                <FormControl component="fieldset">
                    <CustomFormLabel component="legend">{activeQuestion.question[generalStore.locale]}</CustomFormLabel>

                    <RadioGroup
                        value={currAnswerId}
                        onChange={(_, value: string) => {
                            setAnsweredQuestions(answeredQuestions.map((q, i) => {
                                if (i === activeQuestionIndex) {
                                    return {
                                        ...q,
                                        selectedAnswerId: parseInt(value)
                                    }
                                }
                                return q
                            }))
                        }}
                    >
                        {activeQuestion.options.map(option => (
                            <FormControlLabel
                                key={option.id}
                                value={option.id}
                                control={<Radio color="primary" />}
                                label={option[generalStore.locale]}
                            />
                        ))}
                    </RadioGroup>
                </FormControl>
            </Container>

            <ButtonContainer>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClick}
                    disabled={!currAnswerId}
                >
                  { lastQuestion ? "Submit" : "Next" }
                </Button>
            </ButtonContainer>
        </>
    )
}

export function TrainingSiteContent() {
    const [questions, setQuestions] = React.useState<null|Question[]>(null)
    const [wrongAnswer, setWrongAnswer] = React.useState(false)

    const [videoDone, setVideoDone] = React.useState(false)

    async function submit(answeredQuestions: Question[]) {
        if (!answeredQuestions) {
            generalStore.errorMessage = t("training.unexpectedError")
            return
        }

        try {
            const resp = await API.verifyAnsweredQuestions(answeredQuestions)

            if (resp.trainingCompleted) {
                await authStore.getUserProfile(["patientPLUS"], authStore.userProfile?.user.uid, "");
                history.push(Routes.PATIENT.ROOT)
                return
            }
            
            setWrongAnswer(true)
        } catch (e) {
            generalStore.errorMessage = t("training.unexpectedError")
        }

    }

    async function reloadQuestions() {
        generalStore.isLoading = true
        if (questions) {
            setQuestions(null)
        }

        let resp
        try {
            resp = await API.getTrainingQuestions()
        } catch (e) {
            generalStore.isLoading = false
            generalStore.errorMessage = t("training.unexpectedError")
        }

        if (!resp?.questions) {
            generalStore.isLoading = false
            generalStore.errorMessage = t("training.unexpectedError")
            return
        }

        generalStore.isLoading = false
        setQuestions(resp.questions)
    }

    if (!videoDone) {
        return (
            <>
                <IntroContainer>
                    <p>{t("training.intro.salutation")}</p>
                    <p>{t("training.intro.firstPar")}</p>
                    <p>{t("training.intro.secondPar")}</p>
                    <p>
                        <u>{t("training.instr.label")}</u>
                        <ol>
                            <li>{t("training.instr.first")}</li>
                            <li>{t("training.instr.second")}</li>
                            <li>{t("training.instr.third")}</li>
                        </ol>
                    </p>
                    <p>{t("training.intro.closing")}</p>

                    <iframe
                        width="960"
                        height="540"
                        src="https://www.youtube-nocookie.com/embed/pq6_3pQpOaU?si=JgfmNPJqNaiUrXnR"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                    >
                    </iframe>
                </IntroContainer>
                <ButtonContainer>
                    <p id="trainingDisclaimer">{t("training.disclaimer")}</p>
                    <Button
                        aria-describedby="trainingDisclaimer"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            reloadQuestions()
                            setVideoDone(true)
                        }}
                    >
                        { t("training.answerQuestions") }
                    </Button>
                </ButtonContainer>
            </>
        )
    }

    if (wrongAnswer) {
        return (
            <TryAgainContainer>
                <SentimentVeryDissatisfiedIcon fontSize="large" />
                <Typography>{ t("training.wrongAnswer") }</Typography>
                <TryAgainButtonContainer>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            reloadQuestions()
                            setVideoDone(false)
                            setWrongAnswer(false)
                        }}
                    >
                    { t("training.watchAgain") }
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            reloadQuestions()
                            setWrongAnswer(false)
                        }}
                    >
                    { t("training.tryAgain") }
                    </Button>
                </TryAgainButtonContainer>
            </TryAgainContainer>
        )
    }

    if (!questions) {
        return null
    }

    return (
        <Questions questions={questions} submitFn={submit} />
    )
}

export function TrainingSite() {
    return (
        <>
            <UserNavigation leftComponent={null}/>
            <TrainingSiteContent/>
        </>
    )
}

